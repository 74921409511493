import { loadClient } from '@cx-modules/value-bet/index.cjs.js';

import { isAnalyticsDisabled } from '../../utils/isAnalyticsDisabled/isAnalyticsDisabled.js';

import { html } from '../../utils/lit/html.js';
import { upgradeProperty } from '../../utils/upgradeProperty/upgradeProperty.js';
import {
  fetchCampaignData,
  cxModuleLoader,
  render
} from '../../services/cx-module-helper';
import { Analytics } from '../../services/analytics.js';
import { commaSeparatedValToArrOrVal } from '../../utils/helpers/splitStringToArray.js';

const WIDGET_VERSION = '1.1.0'; // NOTE: This value should match the last version from the widget's CHANGELOG.md file.
const WIDGET_NAME = 'oc-value-bet-widget';

let Q4ApiHost;

try {
  Q4ApiHost = process.env.Q4_API_HOST || window.location.origin;
} catch {}

const wEvent = (name, detail) =>
  new CustomEvent(`${WIDGET_NAME}:${name}`, {
    bubbles: true,
    composed: true,
    detail
  });

const template = document.createElement('template');
template.innerHTML = html`
  <style>
    :host {
      box-sizing: border-box;
      display: block;
      width: 100%;
      height: 100%;
    }

    #root {
      width: inherit;
      height: inherit;
    }
  </style>

  <div id="root"></div>
  <slot name="loading" hidden> Loading... </slot>
  <slot name="error" hidden></slot>
`;

class OCValueBetWidget extends HTMLElement {
  analytics;
  constructor() {
    super();

    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' });
      this.shadowRoot.appendChild(template.content.cloneNode(true));
    }

    this._version = WIDGET_VERSION;
  }

  get slotId() {
    return this.getAttribute('slot-id');
  }

  set slotId(value) {
    this.setAttribute('slot-id', value);
  }

  get sport() {
    return this.getAttribute('sport');
  }

  set sport(value) {
    this.setAttribute('sport', value);
  }

  get competition() {
    return this.getAttribute('competition');
  }

  set competition(value) {
    this.setAttribute('competition', value);
  }

  get participant() {
    return this.getAttribute('participant');
  }

  set participant(value) {
    this.setAttribute('participant', value);
  }

  get player() {
    return this.getAttribute('player');
  }

  set player(value) {
    this.setAttribute('player', value);
  }

  get overrideUrl() {
    return this.getAttribute('override-url');
  }

  set overrideUrl(value) {
    this.setAttribute('override-url', value);
  }

  async connectedCallback() {
    upgradeProperty(this, 'slotId');
    upgradeProperty(this, 'overrideUrl');

    this.#bootstrap();
  }

  async loadEXtenalFonts() {
    const css1 = document.createElement('link');
    css1.href =
      'https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i';
    css1.rel = 'stylesheet';
    const css2 = document.createElement('link');
    css2.href =
      'https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap';
    css2.rel = 'stylesheet';
    document.head.append(css1, css2);
  }

  async loadCustomFont(fontFamily) {
    const linkElement = document.createElement('link');
    linkElement.href = `https://fonts.googleapis.com/css?family=${fontFamily.replace(/ /g, '+')}:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i`;
    linkElement.rel = 'stylesheet';

    document.head.appendChild(linkElement);
  }

  async #bootstrap() {
    const loadingSlot = this.shadowRoot.querySelector('slot[name="loading"]');
    const errorSlot = this.shadowRoot.querySelector('slot[name="error"]');

    loadingSlot.hidden = false;

    try {
      this.loadEXtenalFonts();
      const target = this.shadowRoot.getElementById('root');
      const [{ Component }, campaignData] = await Promise.all([
        cxModuleLoader(loadClient, this.shadowRoot),
        fetchCampaignData(this.slotId, this.overrideUrl)
      ]);

      if (!Component || !target) {
        return;
      }

      const {
        affIds,
        apiKey: affiliateKey,
        categoryId,
        colorBackground,
        colorHeader,
        colorHeaderText,
        colorText,
        eventId,
        fontFamily,
        geolocation,
        groups,
        gtmId,
        marketType = 'fixed',
        oddsFormat,
        playerIds,
        playerProps,
        subeventId,
        teamIds,
        trackingRate,
        variation,
        showNflLogosAndImages
      } = campaignData;

      const analytics = new Analytics(gtmId);

      if (!analytics.getDataLayer()) {
        analytics.integrateAnalytics();
      }

      const cxProps = {
        affiliateKey,
        affiliates: affIds,
        analyticsDataLayer: analytics.getDataLayer(),
        categoryId,
        color: colorHeader,
        colorBackground,
        colorHeaderText,
        colorText,
        dataApiEndpoint: Q4ApiHost,
        disableAnalytics: isAnalyticsDisabled(trackingRate),
        eventId,
        fontFamily,
        oddsFormat,
        group: groups,
        playerIds,
        props: playerProps,
        show: variation,
        state: geolocation,
        teamIds,
        ...(marketType === 'contextual'
          ? {
              participant: commaSeparatedValToArrOrVal(this.participant),
              player: commaSeparatedValToArrOrVal(this.player),
              sport: this.sport,
              competition: this.competition
            }
          : {}),
        ...(!showNflLogosAndImages
          ? {
              imageType: 'avatar'
            }
          : {})
      };

      if (fontFamily) {
        this.loadCustomFont(fontFamily);
      }

      if (subeventId) {
        cxProps.subeventId = `oc-${subeventId}`;
      }
      render(target, Component, cxProps);

      this.dispatchEvent(wEvent('load-success', campaignData));
    } catch (error) {
      errorSlot.hidden = false;

      this.dispatchEvent(wEvent('load-error', { error }));
    } finally {
      loadingSlot.hidden = true;

      this.dispatchEvent(wEvent('load'));
    }
  }
}

if (window.customElements && !window.customElements.get(WIDGET_NAME)) {
  window.customElements.define(WIDGET_NAME, OCValueBetWidget);
}
